<template>
    <div id='driver-list'>
        <v-dialog v-model="table.dialogEdit.open" persistent max-width="1000">
            <v-card>
                <v-card-title>
                    <span class="headline">修改司机</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogEdit.content.real_name" label="姓名" dense
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="table.dialogEdit.content.mobile" label="手机号" dense
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogEdit.content.vehicle_cnt" label="车辆数" dense
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogEdit.content.order_cnt" label="订单数" dense
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="table.dialogEdit.content.work_no" label="身份证" dense
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="table.dialogEdit.content.desc" label="备注" dense
                                    hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined @click="table.dialogEdit.open = false"> 取消 </v-btn>
                    <v-btn color="primary" outlined @click="doEdit"> 提交 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogVehicle.open" persistent max-width="1000">
            <v-card>
                <v-card-title>
                    <span class="headline">司机关联的车辆</span>
                    <v-spacer></v-spacer>
                    <v-btn @click="toAddRelation" color="primary">添加车辆关联</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogVehicle.item.real_name" label="姓名" dense
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogVehicle.item.mobile" label="手机号" dense
                                    hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="table.dialogVehicle.item.work_no" label="身份证" dense
                                    hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table :headers='vehicleHeaders' :items='table.dialogVehicle.list'
                            :loading='table.dialogVehicle.loading' hide-default-footer class="mt-8">
                            <template #[`item.insure_end_at`]='{ item }'>
                                {{ item.insure_end_at == "0001-01-01" ? "-" : item.insure_end_at }}
                            </template>
                            <template #[`item.verify_end_at`]='{ item }'>
                                {{ item.verify_end_at.trim() == "0001-01-01" ? "-" : item.verify_end_at }}
                            </template>
                            <template #[`item.state`]='{ item }'>
                                <v-chip :color="enums.statusColor[item.state]" small>{{ enums.status[item.state]
                                }}</v-chip>
                            </template>
                            <template #[`item.actions`]='{ item }'>
                                <v-btn text small @click="toDelRelation(item)" color="error">解除关联</v-btn>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="table.dialogVehicle.open = false"> 关闭 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogVehicle.dialogAdd.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">添加车辆关联</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="table.dialogVehicle.dialogAdd.content.vehicle_id" label="要关联的车辆"
                                    dense hint="请输入车辆ID、车牌号或者IMEI"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="table.dialogVehicle.dialogAdd.open = false"> 关闭 </v-btn>
                    <v-btn color="primary" outlined @click="addDriverVehicle"> 添加 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogVehicle.dialogDel.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">确定删除车辆{{ table.dialogVehicle.dialogDel.content.vehicle_no }}？</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" outlined @click="table.dialogVehicle.dialogDel.open = false"> 取消 </v-btn>
                    <v-btn color="error" outlined @click="delDriverVehicle"> 提交 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="table.dialogCompany.open" persistent max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">挂靠公司</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-select :items="table.dialogCompany.list" item-text="name" item-value="id"
                                    v-model="table.dialogCompany.content.company_id" label="关联公司" dense
                                    hint="关联的公司"></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-switch v-model="table.dialogCompany.content.auto_bind_vehicle"
                                    :label="table.dialogCompany.content.auto_bind_vehicle ? '自动将关联的车辆挂靠到该公司' : '不自动将关联的车辆挂靠到该公司'"
                                    :hint="table.dialogCompany.content.auto_bind_vehicle ? '自动将关联的车辆挂靠到该公司' : '不自动将关联的车辆挂靠到该公司'"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" outlined @click="table.dialogCompany.open = false"> 取消 </v-btn>
                    <v-btn color="error" outlined @click="bindCompany"> 提交 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <v-text-field v-model='param.key' class='user-search me-3 mb-4' dense hide-details outlined placeholder='搜索'
                    clearable></v-text-field>
                <v-spacer></v-spacer>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total'>
                <template #[`item.state`]='{ item }'>
                    <v-chip :color="enums.statusColor[item.state]" small>{{ enums.status[item.state] }}</v-chip>
                </template>
                            <template #[`item.open_state`]='{ item }'>
                                <v-chip :color="enums.openStatusColor[item.open_state]" small>{{ enums.openStatus[item.open_state]
                                }}</v-chip>
                            </template>
                <template #[`item.actions`]='{ item }'>
                    <v-row dense>
                        <v-col>
                            <v-btn :loading="table.dialogCompany.loading" @click="toBindCompany(item)" color="warning"
                                small>挂靠公司</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn @click="fetchDriverVehicleList(item)" color="primary" small>关联车辆</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn @click="toEdit(item)" color="success" small>更新资料</v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import driverStoreModule from '../driverStoreModule'
import useDriverList from './useDriverList'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user-driver-list'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, driverStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, enums, toEdit, doEdit, toAddRelation, addDriverVehicle, toDelRelation, delDriverVehicle, toBindCompany, bindCompany, fetchDriverList, fetchDriverVehicleList } = useDriverList()

        const vehicleHeaders = [
            { text: '车牌号', value: 'vehicle_no' },
            { text: 'imei', value: 'imei' },
            { text: '保险到期', value: 'insure_end_at' },
            { text: '验车到期', value: 'verify_end_at' },
            { text: '挂靠单位', value: 'company_name' },
            { text: '状态', value: 'state' },
            { text: '备注', value: 'desc' },
            { text: '操作', value: 'actions', align: 'center', sortable: false }
        ]

        return {
            headers, vehicleHeaders,
            param,
            table, enums,
            toEdit, doEdit,
            toDelRelation, delDriverVehicle,
            toAddRelation, addDriverVehicle,
            toBindCompany, bindCompany,
            fetchDriverList, fetchDriverVehicleList,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
