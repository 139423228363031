import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useDriverList() {
    const headers = [
        { text: '真实姓名', value: 'real_name' },
        { text: '手机号', value: 'mobile' },
        { text: '身份证号', value: 'work_no' },
        { text: '名下车辆', value: 'vehicle_cnt' },
        { text: '订单数', value: 'order_cnt' },
        { text: '状态', value: 'state' },
        { text: '接单状态', value: 'open_state' },
        { text: '上报时间', value: 'last_heartbeat' },
        { text: '评分', value: 'star' },
        { text: '挂靠公司', value: 'company_name' },
        { text: '备注', value: 'desc' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        key: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false,
        dialogVehicle: {
            open: false,
            loading: false,
            item: {},
            list: [],
            dialogAdd: {
                open: false,
                loading: false,
                content: {}
            },
            dialogDel: {
                open: false,
                loading: false,
                content: {},
            }
        },
        dialogEdit: {
            open: false,
            loading: false,
            content: {
                "id": "2018dfb356504ca49b64def0eedab385",
                "real_name": "刘",
                "mobile": "13375837500",
                "work_no": "330424199211280015",
                "vehicle_cnt": 0,
                "order_cnt": 0,
                "state": "1",
                "star": 0,
                "company_id": "",
                "company_name": "",
                "desc": "",
                "create_at": "2024-01-03 11:08:16"
            },
        },
        dialogCompany: {
            open: false,
            loading: false,
            item: {},
            content: { id: '', company_id: '', auto_bind_vehicle: false },
            list: [],
        }
    })
    const enums = {
        status: { "1": "正常", "2": "禁用" },
        statusColor: { "1": "success", "2": "error" },
        openStatus: { "0": "-", "1": "开启接单", "2": "关闭接单", 3: "订单进行中", 4: "回程单进行中" },
        openStatusColor: { "1": "success", "2": "error", 3: "warning", 4: "warning" },
    }
    const fetchDriverList = () => {
        table.value.loading = true
        store.dispatch('app-user-driver-list/fetchDriverList', param.value).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list, total } = data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.loading = false)
    }

    const fetchDriverVehicleList = (item) => {
        table.value.dialogVehicle.loading = true
        table.value.dialogVehicle.item = { ...item }
        store.dispatch('app-user-driver-list/fetchDriverVehicleList', { id: item.id }).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogVehicle.list = data || []
            table.value.dialogVehicle.open = true
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.dialogVehicle.loading = false)
    }
    const toAddRelation = () => {
        table.value.dialogVehicle.dialogAdd = {
            open: true,
            loading: false,
            content: { vehicle_id: '' }
        }
    }
    const toEdit = (item) => {
        table.value.dialogEdit = {
            open: true,
            loading: false,
            content: item
        }
    }
    const addDriverVehicle = () => {
        const data = {
            driver_id: table.value.dialogVehicle.item.id,
            vehicle_id: table.value.dialogVehicle.dialogAdd.content.vehicle_id
        }
        store.dispatch('app-user-driver-list/addDriverVehicle', data).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogVehicle.dialogAdd.open = false
            fetchDriverVehicleList(table.value.dialogVehicle.item)
            fetchDriverList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.dialogVehicle.dialogAdd.loading = false)
    }
    const toDelRelation = (content) => {
        table.value.dialogVehicle.dialogDel = {
            open: true,
            loading: false,
            item: {},
            content: content,
            list: [],
        }
    }
    const delDriverVehicle = () => {
        store.dispatch('app-user-driver-list/delDriverVehicle', table.value.dialogVehicle.dialogDel.content.id).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogVehicle.dialogDel.open = false
            fetchDriverVehicleList(table.value.dialogVehicle.item)
            fetchDriverList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.dialogVehicle.dialogDel.loading = false)
    }
    const toBindCompany = (item) => {
        table.value.dialogCompany.loading = true
        store.dispatch('app-user-driver-list/fetchCompanyList', table.value.dialogVehicle.dialogDel.content.id).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list } = data
            table.value.dialogCompany = {
                open: true,
                loading: false,
                item: item,
                content: { id: item.id, company_id: item.company_id, auto_bind_vehicle: false },
                list: list || [],
            }
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally()
    }
    const bindCompany = () => {
        const data = { ...table.value.dialogCompany.content }
        store.dispatch('app-user-driver-list/bindCompany', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogCompany.open = false
            fetchDriverList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.dialogCompany.loading = false)
    }
    const doEdit = () => {
        const data = { ...table.value.dialogEdit.content }
        store.dispatch('app-user-driver-list/fetchDriverEdit', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogEdit.open = false
            fetchDriverList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.dialogEdit.loading = false)
    }

    watch([param], () => {
        fetchDriverList()
    }, { deep: true })
    return {
        headers,
        param,
        table, enums,
        toEdit, doEdit,
        toDelRelation, delDriverVehicle,
        toAddRelation, addDriverVehicle,
        toBindCompany, bindCompany,
        fetchDriverList,
        fetchDriverVehicleList,
    }
}
